import * as Sentry from "@sentry/browser";

const CONSENT_ID_GOOGLE = 755;
export const tcfApiListener = (consentId, callback) => {

    if (typeof __tcfapi !== 'function') return;

    __tcfapi('addEventListener', 2, function (tcData, success) {
        if (!tcData || !success) {
            return;
        }

        if (tcData.eventStatus !== 'useractioncomplete' && tcData.eventStatus !== 'tcloaded') {
            return;
        }

        if (!tcData.vendor || !tcData.vendor.consents) {
            return;
        }

        if (tcData.vendor.consents[consentId]) {
            callback();
        }

        if (tcData.listenerId) {
            __tcfapi('removeEventListener', 2, function () {
            }, tcData.listenerId);
        }
    });
};

export const tcfApiCustomVendors = (consentName, callback) => {

    if (typeof __tcfapi !== 'function') return;

    __tcfapi('addEventListener', 2, function (tcData, success) {
        if (success && (tcData?.eventStatus === 'useractioncomplete' || tcData?.eventStatus === 'tcloaded')) {
            __tcfapi('getCustomVendorConsents', 2, function (cvData, cvSuccess) {
                if (
                    cvSuccess && cvData?.consentedVendors &&
                    cvData.consentedVendors.some(c => c.name === consentName)
                ) {
                    callback();
                }
                if (tcData.listenerId) {
                    __tcfapi('removeEventListener', 2, function () {
                    }, tcData.listenerId);
                }
            });
        }
    });
}
tcfApiListener(CONSENT_ID_GOOGLE, () => {

    const ga4MeasurementId = 'G-E9N30G9WBN'; // Classic + Club Web
    const gaRemarketingId = 'AW-1008049978';

    // load library only once. otherwise it can cause double tracking or tracking errors
    (function () {
        var script = document.createElement("script");
        script.async = true;
        script.type = 'text/javascript';
        var useSSL = 'https:' === document.location.protocol;
        script.src = (useSSL ? 'https:' : 'http:') + '//www.googletagmanager.com/gtag/js?id=' + ga4MeasurementId;
        var node = document.getElementsByTagName('script')[0];
        node.parentNode.insertBefore(script, node);
    })();

    // both trackings are using dataLayer
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
        window.dataLayer.push('undefined' !== typeof arguments ? arguments : []);
    }

    gtag('js', new Date());

    // Track pageview
    gtag('config', ga4MeasurementId,
        {
            'anonymize_ip': true,
            'site_speed_sample_rate': 10,
            'page_title': '/club/',
        });

    // Activate remarketing
    gtag('config', gaRemarketingId,
        {
            'anonymize_ip': true,
            'site_speed_sample_rate': 10,
            'page_title': '/club/',
        });

    // Start Google Tag Manager

    window.gtmDataLayer = window.gtmDataLayer || [];
    window.gtmDataLayer.push({
        'event': 'consentUpdate',
        'consent': {
            'analytics_storage': 'granted'
        }
    });

    (function (w,d,s,l,i) {
        w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
    })(window,document,'script','gtmDataLayer','GTM-M5TWB86');

    // End Google Tag Manager
});

tcfApiCustomVendors('Sentry.io', () => {
    // load Sentry
    Sentry.init({
        dsn: 'https://45c26ef20feba8584eba94344ef7867d@o1183551.ingest.sentry.io/4506075645870080',
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
});